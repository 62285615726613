/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/Redco-hero.jpg";
// import socialHead2 from "../../../images/Banner-Naccho.jpg";
// import inline from "../../../images/image-nurse-baby.jpg";
import inline from "../../../images/Redco-article-1.jpg";
import leftIcon from "../../../images/Left Button.svg";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Siċaŋġu Co | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Siċaŋġu Co"
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "Siċaŋġu Co, the economic development engine of the Siċaŋġu Lak̇ota nation, seeks to improve their tribal relatives' wellbeing through the 7Gen Vision, embracing ancestral wisdom to create a just, abundant and prosperous world for future generations.",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "Siċaŋġu Co’s is guided and inspired by the tribe’s 7Gen Vision, where the impact of decisions must be considered seven generations into the future to help create the type of world the tribe would like to see its descendants living in 175 years from now. Its actions are guided by the question: how do we live today to create a healthy, just, abundant world for our grandchildren?",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "With a deep understanding of the profound challenges faced by its people, Siċaŋġu Co is grounded in ancestral and cultural wisdom of the Siċaŋġu Oyate. They use a holistic and systems-level approach to achieve Wicozani, or “the good way of life”, and create holistic health, economic self-sufficiency, cultural revitalisation, sustainable housing, food sovereignty, climate resilience and shared, lasting prosperity.",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "Siċaŋġu Co draws strength from the timeless words of their ancestors: “Nake Nula Wauŋ” – I am always prepared, anytime, anywhere, for anything.",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title: "‘Option counselling’ with mothers  ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title: "Partnerships with the police ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title: "Various hospitals where children are abandoned",
        //     heading: [],
        //   },
        // ]}
        butlletdesign="YES"
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        // video="YES"
        statements={[
          {
            id: "",
            title:
              "The Siċaŋġu Lak̇ota nation is one of the poorest communities in the United States, facing a wide range of poverty-related development challenges such as:",
            heading: [],
          },
        ]}
        // heading={[
        //   {
        //     children: [
        //       {
        //         text: "The Siċaŋġu Lak̇ota nation is one of the poorest communities in the United States, facing a wide range of poverty-related development challenges such as:",
        //       },
        //     ],
        //     type: "paragraph",
        //   },
        // ]}
        listing={[
          "A 75% unemployment rate amongst tribal members",
          "Median household income only one-third of the broader United States population ",
          "Life expectancy 9 years below the national average",
        ]}
        Articletitle="Challenge faced by organisation "
        afterlisting="Their economy is heavily reliant on federal funding and lacks a strong or diversified private sector. Further, there is no prioritised economic development plan to leverage the tribe’s resources and competitive advantages to address these issues. Without a strong intervention, the tribe faces the real risk of descending further into poverty."
      />
      {/* <ValuesCollection
        key=""
        title="Challenge faced by organisation "
        changeDesign="YES"
        styleclass="pt-5px"
        heading={[
          {
            children: [
              {
                text: "Telehealth can deliver significant benefits including increased access to remote communities, extended capacity and reach of community-based care, and increased efficiency and decreased travel time for patients and providers. However, reliable connectivity, and digital literacy and capability is critical to ensuring successful delivery and uptake of Telehealth services. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
            heading: [],
          },
          {
            id: "",
            title:
              "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
            heading: [],
          },
        ]}
      /> */}
      <ValuesCollection
        key=""
        title="How we helped"
        className="bg-light-blue pt-5"
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        statements={[
          {
            id: "",
            title:
              "Built support around a clear vision with a shared view of “What Good Looks Like” and created alignment on where the tribe should focus its efforts",
            heading: [],
          },
          {
            id: "",
            title:
              "Prioritised sectors with the most potential by leveraging resources and competitive advantages in renewable power, construction and agriculture",
            heading: [],
          },
          {
            id: "",
            title:
              "Defined what needs to happen to eliminate barriers hindering economic growth, including removing regulatory and governance constraints and building capability through partnerships and internal skills development programs",
            heading: [],
          },
          {
            id: "",
            title:
              "Helped develop a roadmap for implementation that identifies funding requirements and defines specific strategies, needed resources and timelines",
            heading: [],
          },
          {
            id: "",
            title:
              "Worked with tribal members to identified potential sources of equity funding",
            heading: [],
          },
        ]}
      />
      {/* <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        statements={[
          {
            id: "",
            title:
              "Delivered a 10-year economic development roadmap (EDR) focused on developing renewable energy, agriculture and construction that will deploy a total project value of $0.5bn across four strategies, $210m of which will be equity funded and the remainder will be debt.",
            heading: [],
          },
          {
            id: "",
            title:
              "If successfully implemented, the roadmap has the potential to:",
            heading: [],
          },
        ]}
        listing={[
          "Double the economic growth rate from 2% to 4%",
          "Add over $1bn to tribal income over the next decade",
          "Create 3,000+ new jobs (an 80% increase over the existing baseline)",
          "Increase economic diversification and create economic resilience",
          "Developed a partnership and capability development plan to support the tribe in successfully implementing the EDR",
        ]}
        // heading={[
        //   {
        //     children: [
        //       {
        //         text: "Delivered a 10-year economic development roadmap (EDR) focused on developing renewable energy, agriculture and construction that will deploy a total project value of $0.5bn across four strategies, $210m of which will be equity funded and the remainder will be debt.",
        //       },
        //     ],
        //     type: "paragraph",
        //   },
        //   {
        //     children: [
        //       {
        //         text: "If successfully implemented, the roadmap has the potential to: ",
        //       },
        //     ],
        //     type: "paragraph",
        //   },
        // ]}
        Articletitle="Impact achieved"
      /> */}
      <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        butlletdesign="YES"
        heading={[
          {
            children: [
              {
                text: "Delivered a 10-year economic development roadmap (EDR) focused on developing renewable energy, agriculture and construction that will deploy a total project value of $0.5bn across four strategies, $210m of which will be equity funded and the remainder will be debt.",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "If successfully implemented, the roadmap has the potential to:",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title: "Double the economic growth rate from 2% to 4%",
            heading: [],
          },
          {
            id: "",
            title: "Add over $1bn to tribal income over the next decade",
            heading: [],
          },
          {
            id: "",
            title:
              "Create 3,000+ new jobs (an 80% increase over the existing baseline)",
            heading: [],
          },
          {
            id: "",
            title:
              "Increase economic diversification and create economic resilience",
            heading: [],
          },
          {
            id: "",
            title:
              "Developed a partnership and capability development plan to support the tribe in successfully implementing the EDR",
            heading: [],
          },
        ]}
      />

      {/* <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        butlletdesign="YES"
        // heading={[
        //   {
        //     children: [
        //       {
        //         text: "Impacted 1000 lives – working off a 1:5 ratio, 5 people are directly impacted by donation of a light, lasting for 10 years (which contributes to 1.46 million extra study hours ",
        //       },
        //     ],
        //     type: "paragraph",
        //   },
        // ]}
        statements={[
          {
            id: "",
            title:
              "A prioritised list of initiatives to increase funding by 125% ",
            heading: [],
          },
          {
            id: "",
            title:
              "Fully mapped out donor journey where all interactions with current and potential donors are clearly defined",
            heading: [],
          },
          {
            id: "",
            title:
              "Roadmap with action plans on how to unlock possible donation opportunities ",
            heading: [],
          },
          {
            id: "",
            title:
              "Increased ease of payment for donors on social media sites through a mobile payment application ",
            heading: [],
          },
          {
            id: "",
            title:
              "Software comparison that was cost-effective and would facilitate the donor journey",
            heading: [],
          },
          {
            id: "",
            title:
              "Additional R146,000 (approximately US$9,200) donations raised under the internal fundraising event ",
            heading: [],
          },
        ]}
      /> */}
      <p className="col-span-12 col-start-2 text-center p-20px ga-mt--30">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a href=" https://www.sicangu.co/" className="text-dark-blue">
            https://www.sicangu.co/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
